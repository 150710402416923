import { Link, graphql } from "gatsby"
import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import SplitTile from "../../components/splitTile"

/** @jsx jsx */
import {
  jsx,
  Button,
  Heading,
  Styled,
  Text,
  Box,
  Link as ThemeUILink,
} from "theme-ui"

import lyrikCover from "../../images/books/cover_uschtrin_weber.jpg"

const Page = ({ data, location }) => (
  <Layout>
    <SEO
      image={lyrikCover}
      location={location}
      title="Zwischen Handwerk und Inspiration"
      description="Lyrik schreiben und veröffentlichen"
    />
    <SplitTile
      image={data.cover.childImageSharp.fluid}
      imageInfo="Cover von »Zwischen Handwerk und Inspiration«"
    >
      <Heading as="h1" sx={{ fontSize: 4, fontWeight: 900 }}>
        Zwischen Handwerk und Inspiration
      </Heading>
      <Text>Lyrik schreiben und veröffentlichen</Text>
      <p sx={{ fontSize: 4, fontWeight: "bold" }}>18,90 EUR</p>
      <Text>
        <strong>Achtung:</strong> Print-Ausgabe ist vergriffen.
        <br /> 
        <font color="red">Jetzt als E-Book/PDF für <bold>9,99 EUR</bold> in diversen Online-Shops.</font>
        <br />
        <br />
      </Text>
    </SplitTile>
    <Box>
      <Text variant="subheading">Bibliografische Angaben</Text>
      <Text variant="default">
        Martina Weber:
        <br />
        Zwischen Handwerk und Inspiration.
        <br />
        Lyrik schreiben und veröffentlichen
        <br />
        3., vollständig überarbeitete Auflage.
        <br />
        Uschtrin Verlag: München 2011
      </Text>
      <Text variant="default">
        Mit Beiträgen von Karin Fellner, Norbert Hummelt und Christian Schloyer.
        Sowie Gesprächen mit Kurt Drawert und Nathalie Schmid.
      </Text>
      <Text variant="default">
        240 Seiten, Preis: 18,90 Euro
        <br />
        ISBN: 978-3-932522-09-3
      </Text>
      <Text variant="subheading">Zum Inhalt</Text>
      <Text variant="default">Vorwort zur 3. Auflage ... 8</Text>

      <Text variant="default">Teil 1: Lyrik schreiben</Text>

      <Text variant="default">
        1. Eine lyrische Stimme entwickeln ... 12
        <br />
        1.1. Was ist ein Gedicht? ... 12
        <br />
        1.2. Zwischen Regeln und Regellosigkeit ... 15
        <br />
        1.3. Lyrik lesen lernen ... 18
        <br />
        2. Fensterausschnitt, Antennendraht (Norbert Hummelt) ... 21
        <br />
        3. Der Zeilenbruch ... 29
        <br />
        3.1. Zeilenstil ... 29
        <br />
        3.2. Zeilensprung oder Enjambement ... 30
        <br />
        4. Über die Wirklichkeit hinausgetragen: die Metapher ... 36
        <br />
        4.1. Die Metapher und wie man sie bildet ... 36
        <br />
        4.2. Arten von Metaphern ... 39
        <br />
        4.3. Sonderform: Synästhesie ... 41
        <br />
        4.4. Die hohe Kunst: die zweite Ebene ... 42
        <br />
        5. Transportieren, was mich berührt hat: Nathalie Schmid im Gespräch mit
        Martina Weber ... 43
        <br />
        6. Der Reim und andere Klangelemente ...55
        <br />
        6.1. Begriff und Funktion des Reims ...55
        <br />
        6.2. Reimformen und Reimfolgen ... 55
        <br />
        6.3. Andere Klangelemente ... 64
        <br />
        6.4. Anregungen zur eigenen Arbeit mit dem Reim ... 68
        <br />
        7. Mitschwingen. Widerhall: Möglichkeiten und Ziele eines
        Lyrik-Lektorats (Karin Fellner) ... 70
        <br />
        8. Metrum und Rhythmus ... 80
        <br />
        8.1. Verhältnis zwischen Metrum und Rhythmus ... 80
        <br />
        8.2. Funktion des Rhythmus ... 83
        <br />
        8.3. Taktarten ... 84
        <br />
        8.4. Auftakt, Kadenz, Zäsur ... 84
        <br />
        8.5. Versmaße ... 85
        <br />
        8.6. Freie Rhythmen ... 90
        <br />
        9. 28 Anregungen zur Arbeit an eigenen Gedichten ... 94
        <br />
        10. Ist Lyrik ein Erkenntnisinstrument? (Christian Schloyer) ... 124
        <br />
        11. Kommentierte Literaturempfehlungen ... 143
        <br />
        11.1. Handwerk ... 143
        <br />
        11.2. Reflexionen über Lyrik, poetologische Diskussionen und den
        Literaturbetrieb ... 147
        <br />
        11.3. Anthologien ... 155
        <br />
        11.4. Literaturzeitschriften ... 165
        <br />
        11.5. Weitere Internetseiten mit oder über Lyrik ... 169
        <br />
        11.6. Fremdsprachige (vor allem englische) Lyrik ... 70
        <br />
        11.7. Lyrik hören ... 174
        <br />
        11.8. Lyrikgeschichte ... 177
        <br />
        12. Viel Freizeit und zu viele Vögel: 21 Thesen und Gedanken aus der
        Diskussionsrunde zum Literarischen März 2007 ... 179
        <br />
        13. Der Text braucht Identität: Kurt Drawert im Gespräch mit Martina
        Weber ... 182
        <br />
      </Text>

      <Text variant="default">Teil 2: Lyrik veröffentlichen</Text>

      <Text variant="default">
        1. Der Begriff der Veröffentlichung ... 192
        <br />
        2. Austausch mit anderen Schreibenden ... 193
        <br />
        3. Literaturzeitschriften und Anthologien ... 197
        <br />
        4. Wartezeit und Absagen ... 200
        <br />
        5. Lesungen ... 202
        <br />
        6. Wettbewerbe ... 205
        <br />
        7. Der eigene Gedichtband ... 207
        <br />
        7.1. Verlagsadressen und -programme ... 207
        <br />
        7.2. Der richtige Zeitpunkt für den ersten Band und der passende Verlag
        ... 223
        <br />
        8. Der Verlagsvertrag ... 227
        <br />
        9. Book on Demand als Alternative? ... 233
        <br />
        10. Nach dem Verlagsvertrag ... 234
        <br />
      </Text>

      <Text variant="default">Die Autorin ... 37</Text>
    </Box>
    <script type="application/ld+json">
      {`{
        "@context": "https://schema.org",
        "@type": "Book",
        "@id": "https://shop.autorenwelt.de/products/zwischen-handwerk-und-inspiration-lyrik-schreiben-und-veroffentlichen-von-martina-weber#creative-work",
        "url": "https://www.uschtrin.de/buehcer/weber-lyrik-schreiben-und-veroeffentlichen",
        "sameAs": "https://shop.autorenwelt.de/products/zwischen-handwerk-und-inspiration-lyrik-schreiben-und-veroffentlichen-von-martina-weber",
        "name": "Zwischen Handwerk und Inspiration - Lyrik schreiben und veröffentlichen",
        "abstract": "Mit Beiträgen von Karin Fellner, Norbert Hummelt und Christian Schloyer. Sowie Gesprächen mit Kurt Drawert und Nathalie Schmid.",
        "isbn": "9783932522093",
        "bookEdition": "3., vollständig überarbeitete Auflage",
        "publisher": {
          "@type": "Organization",
          "@id": "https://www.uschtrin.de#organisation",
          "name": "Uschtrin Verlag"
        },
        "author": {
          "@type": "Person",
          "givenName": "Martina",
          "familyName":"Weber",
          "sameAs": "https://www.autorenwelt.de/person/martina-weber"
        },
        "image": "https://www.uschtrin.de${lyrikCover}",
        "numberOfPages": "240"
      }`}
    </script>
  </Layout>
)

export const query = graphql`
  query {
    cover: file(relativePath: { eq: "books/cover_uschtrin_weber.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 512) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`

export default Page
